import React from "react";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

const HomeBannerImage = ({file_name}) => {

    //console.log("file_name ==>", `../../images/${file_name}`)
    return (
        <>
        <StaticImage src={`../../images/home_banner.jpg`} layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="65" className="img-fluid banner_img" />
        </>
    )

}

export default HomeBannerImage